import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import {
  BasicInformation,
  PaymentInCertificate,
  PaymentInEuro,
  PaymentInRoubles,
  Refunds
} from '../molecules/SposobyOplaty'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { Grid } from '../atoms/Grid'
import { Text } from '../atoms/Text'
import { Title } from '../atoms/Title'
import { size } from '../constants'

const Payments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 92px;
  margin-bottom: 48px;

  @media (max-width: ${size.lg}) {
    margin-top: 64px;
    margin-bottom: 40px;
  }
  @media (max-width: ${size.md}) {
    margin-top: 40px;
    margin-bottom: 32px;
  }
  @media (max-width: ${size.sm}) {
    margin-top: 32px;
  }
  @media (max-width: ${size.xs}) {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  ${Title.H1} {
    margin-bottom: 8px;
  }

  ${Title.H3} {
    margin: 16px 0px;

    @media (max-width: ${size.sm}) {
      font-size: 18px;
      line-height: 26px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }

  ${Title.H4} {
    margin-top: 8px;
    margin-bottom: 16px;

    @media (max-width: ${size.sm}) {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  ${Title.H5} {
    margin-bottom: 8px;
  }

  ${Text.Medium} {
    font-weight: 400;
    margin-bottom: 16px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 8px;
    }
  }

    @media (max-width: ${size.sm}) {
      width: 341px;
      margin-bottom: 8px;
    }

    @media (max-width: ${size.xs}) {
      width: 257px;
    }
  }
`

const SposobyOplaty = () => (
  <App>
    <SEO
      description="Узнайте подробно о всех способах оплаты и условиях возврата | онлайн-сервис психологической помощи YouTalk"
      title="Способы оплаты | YouTalk"
    />
    <BreadcrumbSEO />
    <Header />
    <BreadCrumbs />
    <Grid>
      <Payments>
        <BasicInformation />
        <PaymentInRoubles />
        <PaymentInEuro />
        <PaymentInCertificate />
        <Refunds />
      </Payments>
    </Grid>
    <Footer />
  </App>
)

export default SposobyOplaty
