import React from 'react'
import styled from 'styled-components'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'

const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 16px;

  & img {
    width: 394px;
    height: inherit;
    display: flex;
    justify-content: center;

    & img {
      width: 100%;
    }

    @media (max-width: ${size.sm}) {
      width: 341px;
    }

    @media (max-width: ${size.xs}) {
      width: 257px;
    }
`

const Stripe = () => (
  <Block>
    <Title.H4>Платёжная система PayPal</Title.H4>
    <Text.Medium>
      Картой иностранного банка можно оплатить без комиссии через PayPal.
    </Text.Medium>
    <img alt="Оплата Stripe" src="/img/payments/stripe.svg" />
  </Block>
)

const Invoice = () => (
  <Block>
    <Title.H4>Банковский перевод по реквизитам</Title.H4>
    <Text.Medium>
      Мы принимаем банковский перевод по реквизитам. Уточните реквизиты у
      специалиста по подбору психолога.
    </Text.Medium>
  </Block>
)

export const BasicInformation = () => (
  <Block>
    <Title.H1>Способы оплаты</Title.H1>
    <Title.H3>Оплата</Title.H3>
    <Text.Medium>Мы принимаем оплаты из любой точки мира. </Text.Medium>
    <Text.Medium>
      1.{'  '}
      <a href="https://youtalk.ru/form/" rel="noreferrer" target="_blank">
        Заполните анкету
      </a>{' '}
      на подбор психолога у нас на сайте или подберите специалиста{'  '}
      <a href="https://youtalk.ru/wizard/" rel="noreferrer" target="_blank">
        самостоятельно
      </a>
      . <br />
      2. При самостоятельном выборе психолога оплатить сессию можно прямо в
      личном кабинете или в мобильном приложении YouTalk для{'  '}
      <a
        href="https://apps.apple.com/ru/app/youtalk/id6447007809"
        rel="noreferrer"
        target="_blank"
      >
        iPhone
      </a>{' '}
      и{'  '}
      <a
        href="https://play.google.com/store/apps/details?id=com.youtalk.clientapp&hl=en_US"
        rel="noreferrer"
        target="_blank"
      >
        Android
      </a>{' '}
      . Если выбираете со специалистом, то ссылку он направит вам в Telegram или
      WhatsApp.
      <br />
      3. После оплаты на вашем балансе появятся баллы, которые можно потратить,
      чтобы оплатить сессию с психологом. Записаться на сессию можно из личного
      кабинета, выбрав удобное время в календаре.
      <br />
      4. После записи на сессию психолог свяжется с вами для выбора платформы
      для встречи и уточнения запроса в удобном мессенджере.
    </Text.Medium>
  </Block>
)

export const PaymentInRoubles = () => (
  <Block>
    <Title.H3>Оплата российской картой (в рублях)</Title.H3>
    <Text.Medium>
      Оплатить можно картой российского банка без комиссии через CloudPayments.
      Мы принимаем к оплате карты Visa, Mastercard и МИР.
    </Text.Medium>
    <PaymentsImages />
    <img alt="Оплата CloudPayments" src="/img/payments/cloudPayments.svg" />
  </Block>
)

export const PaymentInEuro = () => (
  <Block>
    <Title.H3>Оплата зарубежной картой (в евро)</Title.H3>
    <Stripe />
    <Invoice />
  </Block>
)

export const PaymentInCertificate = () => (
  <Block>
    <Title.H3>Сертификатом</Title.H3>
    <Text.Medium>
      Если вам подарили сертификат, нужно ввести код сертификата на странице
      выбора оплаты в поле «Сертификат» во время пополнения баланса в личном
      кабинете. Сертификат действует 6 месяцев с момента покупки (дата окончания
      действия указана на сертификате). Сертификаты, приобретённые на сессии, а
      не на баллы, автоматически конвертируются в баллы в личном кабинете.
      Сертификатом важно воспользоваться в течение полугода и пополнить им свой
      баланс, но не обязательно тратить всю сумму сертификата в течение
      полугода.
    </Text.Medium>
  </Block>
)

export const Refunds = () => (
  <Block>
    <Title.H3>Возвраты</Title.H3>
    <Text.Medium>
      Если по каким-либо причинам вам не подойдут услуги нашего сервиса, вы в
      любой момент можете запросить у нас полный или частичный возврат средств.
    </Text.Medium>
    <Text.Medium>
      Для этого нужно написать в чат со специалистом нашего сервиса. Мы соберём
      обратную связь, чтобы понять все детали ситуации, и на основе этого вместе
      с вами примем решение о компенсации.
    </Text.Medium>
    <Text.Medium>
      Возврат можно сделать только на ту карту или счет, с которых были списаны
      средства.
    </Text.Medium>
  </Block>
)

export const PaymentsImages = styled(({ className }) => {
  const wWidth = useSelector(selectDeviceSize)
  return (
    <div className={className}>
      {(wWidth > 767 || wWidth < 450) && (
        <>
          <img alt="Банковская карта" src="/img/payments/card-small.svg" />
          <img alt="Оплата Я Pay" src="/img/payments/y-pay-small.svg" />
          <img
            alt="Оплата Tinkoff Pay"
            src="/img/payments/tinkoff-pay-small.svg"
          />
          <img alt="Оплата сбп" src="/img/payments/sbp-small.svg" />
        </>
      )}

      {wWidth < 767 && wWidth > 450 && (
        <>
          <img alt="Банковская карта" src="/img/payments/card.svg" />
          <img alt="Оплата Я Pay" src="/img/payments/y-pay.svg" />
          <img alt="Оплата Tinkoff Pay" src="/img/payments/tinkoff-pay.svg" />
          <img alt="Оплата сбп" src="/img/payments/sbp.svg" />
        </>
      )}
    </div>
  )
})`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 24px;
  width: 80%;

  @media (max-width: ${size.lg}) {
    gap: 8px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }

  @media (max-width: ${size.xxs}) {
    grid-template-columns: repeat(1, 2fr);
    width: 394px;
    gap: 0;
  }

  img {
    width: 193px;

    @media (max-width: ${size.lg}) {
      width: 160px;
    }

    @media (max-width: ${size.xs}) {
      width: 170px;
    }

    @media (max-width: ${size.xxs}) {
      width: 288px;
    }
  }
`
