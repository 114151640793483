import React, { useMemo } from 'react'
import navLinksWithNameData from './data.json'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrowRight } from '../../../static/img/icons/arrow-right-grey.svg'
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo'
import { Grid } from '../../atoms/Grid'
import { useLocation } from '@reach/router'

const linkCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #333333b2;
`
const NavLink = styled.a.attrs(() => ({ itemProp: 'item' }))`
  ${linkCss}

  ${({ disable }) =>
    disable &&
    css`
      pointer-events: none;
    `}

  &:hover {
    text-decoration: underline;
    color: #2963a3;
  }
`

const seoLiItemProps = {
  itemProp: 'itemListElement',
  itemScope: true,
  itemType: 'http://schema.org/ListItem'
}

const NavLinkWithArrow = styled(
  ({ className, to, children, content, disable }) => (
    <li {...seoLiItemProps} className={className}>
      <NavLink partiallyActive disable={disable} href={to}>
        <span itemProp="name">{children}</span>
      </NavLink>
      {!disable && <ArrowRight />}
      <meta content={content} itemProp="position" />
    </li>
  )
)`
  height: inherit;
  display: flex;
  align-items: center;

  & ${NavLink} {
    display: inline-block;
    width: fit-content;
    margin-right: 4px;
    white-space: nowrap;
    width: 100%;

    & span {
      max-width: 100%;
    }
  }
`

const getFullPath = ({ originUrl, path, position, breadCrumbs }) => {
  const urlEnd = position === 1 ? '' : '/'
  const breadFullPath =
    position > 2
      ? breadCrumbs
          .slice(1, position)
          .map(({ path }) => path)
          .join('')
      : path
  return `${originUrl}${breadFullPath}${urlEnd}`
}

const getBreadCrumbs = ({ pathname, pageName }) => {
  const parsedPathnames = pathname
    .match(/\/[a-zA-Z0-9-]*/g)
    .filter((path) => path !== '/')

  const currentPathName = parsedPathnames[parsedPathnames.length - 1]

  return [
    ...navLinksWithNameData
      .filter(({ path }) => ['/', ...parsedPathnames].includes(path))
      .concat(pageName ? [{ name: pageName, path: currentPathName }] : [])
      .map((breadCrumb, index, breadCrumbs) => ({
        ...breadCrumb,
        path: getFullPath({
          originUrl: String(process.env.GATSBY_SITEURL),
          path: breadCrumb.path,
          position: index + 1,
          breadCrumbs
        })
      }))
  ]
}

const useBreadCrubms = ({ pageName }) => {
  const { pathname } = useLocation()

  return useMemo(
    () =>
      getBreadCrumbs({
        pathname: pathname,
        pageName
      }),
    [pathname, pageName]
  )
}

export const BreadcrumbSEO = ({ pageName }) => {
  const breadCrumbs = useBreadCrubms({ pageName })

  return (
    <BreadcrumbJsonLd
      itemListElements={breadCrumbs.map(({ path, name }, index) => ({
        position: index + 1,
        name,
        item: path
      }))}
    />
  )
}

const BreadCrumbList = styled(({ className, breadCrumbs, pageName }) => (
  <ol
    itemScope
    className={className}
    itemType="http://schema.org/BreadcrumbList"
  >
    {breadCrumbs.map(({ path, name }, index, breadCrumbsArray) =>
      breadCrumbsArray.length !== index + 1 ? (
        <NavLinkWithArrow key={path} content={index + 1} to={path}>
          {name}
        </NavLinkWithArrow>
      ) : (
        <NavLinkWithArrow key={name} disable content={index + 1} to={path}>
          {pageName ?? name}
        </NavLinkWithArrow>
      )
    )}
  </ol>
))`
  display: flex;
  flex-direction: row;
  height: 18px;
  gap: 8px;
  align-items: center;
  list-style-type: none;
  overflow: hidden;
  width: 100%;

  & li:last-child {
    overflow: hidden;

    & ${NavLink} {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: auto;
      width: 100%;
      margin-right: 0 !important;

      & span {
        width: inherit;
      }
    }
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`

export const BreadCrumbs = styled(
  ({ className, pageName, customBreadCrumbs, fill }) => {
    const breadCrumbs = useBreadCrubms({ pageName })

    return (
      <div className={className}>
        <Grid>
          <BreadCrumbList
            breadCrumbs={customBreadCrumbs ?? breadCrumbs}
            fill={fill}
            pageName={pageName}
          />
        </Grid>
      </div>
    )
  }
)`
  width: 100%;
  background: ${({ fill }) => fill ?? 'transparent'};

  & ${Grid} {
    position: relative;
    padding-top: 16px;
  }
`
